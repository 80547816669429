<template>
    <ValidationObserver ref="observer" tag="form" v-slot="{ invalid, handleSubmit }">
        <v-form @submit.prevent="handleSubmit(save)">
            <v-row>
                <v-col cols="12" sm="12" md="6">
                    <ValidationProvider name="Event scheduler duration" rules="required" v-slot="{ errors }">
                        <v-text-field outlined dense :error-messages="errors" type="number" v-model="form.eventschedulerduration" label="Event scheduler duration">
                        </v-text-field>
                    </ValidationProvider>
                </v-col>
                <v-col cols="12" sm="12" md="6">
                    <vue-timepicker class="mt-5" v-model="form.evetschedulertime" :minute-interval="5"></vue-timepicker>
                </v-col>
                <v-col cols="12" sm="12" md="12">
                    <v-row justify="end">
                        <v-btn color="success darken-1" type="submit" :loading="loading_btn"
                             :disabled="invalid || isEmpty(form) || loading_btn">Save</v-btn>
                    </v-row>
                </v-col>
            </v-row>
            <alerts
      v-if="alert.show"
      v-on:close_alert="closeAlert"
      v-on:accept_alert="acceptAlert"
      :properties="alert"
    ></alerts>
        </v-form>
    </ValidationObserver>
</template>
  
<script>
import _ from "lodash";
import 'vue2-timepicker/dist/VueTimepicker.css';
import Alerts from "@/components/Alerts.vue";

export default {
    name: "ManageRemindersEmailSchedule",
    components: {
        Alerts
    },
    data() {
        return {
            form: {},
            loading_btn: false,
            alert: {
        type: "success",
        show: false,
        header: "",
        body: "",
      },

        };
    },
    watch: {
    },
    computed: {
    },
    mounted() {
        this.fetchCurrentTemp();
    },
    methods: {
        closeAlert() {
      this.alert.show = false;

      if (this.alert.options == "redirectcustomer") {
        this.$store.dispatch("deleteWindowByUuid", { uuid: this.win.uuid });
        this.$store.dispatch("addWindow", {
          name: "customerList",
          component: "CustomersList",
          unique: true,
        });
        this.$store.dispatch("reloadData", "customers");
      }
    },
    acceptAlert() {
      this.alert.show = false;

      if (this.alert.options == "deletecustomer") {
        this.deleteCustomer();
      }
    },
    showAlert(type, header, body, options = null) {
      type = type == null ? "danger" : type;
      this.alert.type = type;
      this.alert.header = header;
      this.alert.body = body;
      this.alert.show = true;
      this.alert.options = options != null ? options : null;
    },
        isEmpty(data) {
            return _.isEmpty(data);
        }, 
         fetchCurrentTemp() {
          this.form.eventschedulerduration = "";
          this.form.evetschedulertime='05:00';
          this.form.userId = JSON.parse(localStorage.getItem("user")).id;
    },
        async save() {
                this.loading_btn = true;
                    let obj = {
                        eventschedulerduration: this.form.eventschedulerduration,
                        evetschedulertime: this.form.evetschedulertime
                    };
                    this.$API.remindersemailschedule
                        .updateRemindersEmailSchedule(obj)
                        .then((response) => {
                            this.loading_btn = false;
                            console.log(response);
                            this.showAlert(
                                "success",
                                "Actualización exitosa",
                                "Recordatorio de correo electrónico actualizado correctamente"
                            );                 
                        })
                        .catch((err) => {
                            this.loading_btn = false;
                            console.log(err);
                            this.showAlert(
                                "danger",
                                "Error",
                                "Error al actualizar el recordatorio de correo electrónico"
                            );
                        });
        },
    },
};
</script>
  
<style scoped></style>
  